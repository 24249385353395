import { INavData } from "@coreui/angular";

export interface MyINavData extends INavData {
  permission?: string;
}

export const navItems: MyINavData[] = [
  {
    name: "Dashboard",
    url: "/video",
    icon: "icon-speedometer",
    badge: {
      variant: "danger",
      text: "In Dev",
    },
  },
  {
    title: true,
    name: "CCTV Control",
  },
  {
    name: "Live",
    url: "/video/",
    icon: "fa fa-video-camera",
    permission: "live",
  },
  {
    name: "Map",
    url: "/map",
    icon: "fa fa-map-o",
  },
  /*{
    name: "Playback",
    url: "/video/playback",
    icon: "fa fa-archive",
    permission: "playback",
  },*/
  /*{
    title: true,
    name: "Configuration",
  },*/
  /*{
    name: "Video Wall",
    url: "/video/wall",
    icon: "fa fa-th",
    permission: "walls",
  },*/
  /*{
    name: "Sequence",
    url: "/video/sequence",
    icon: "fa fa-spinner",
    permission: "sequence",
    badge: {
      variant: "danger",
      text: "In Dev",
    },
  },*/
  /*{
    name: "VNC Desktops",
    icon: "fa fa-desktop",
    permission: "vnc",
    children: [
      {
        name: "Desktop 1",
        url:
          "http://192.168.1.250:6081/vnc_lite.html?host=192.168.1.250&port=6081&password=password",
        icon: "fa fa-long-arrow-right",
        attributes: { target: "_blank", rel: "noopener" },
      },
      {
        name: "Desktop 2",
        url:
          "http://192.168.1.250:6082/vnc_lite.html?host=192.168.1.250&port=6082&password=password",
        icon: "fa fa-long-arrow-right",
        attributes: { target: "_blank", rel: "noopener" },
      },
      {
        name: "Desktop 3",
        url:
          "http://192.168.1.250:6083/vnc_lite.html?host=192.168.1.250&port=6083&password=password",
        icon: "fa fa-long-arrow-right",
        attributes: { target: "_blank", rel: "noopener" },
      },
      {
        name: "Desktop 4",
        url:
          "http://192.168.1.250:6084/vnc_lite.html?host=192.168.1.250&port=6084&password=password",
        icon: "fa fa-long-arrow-right",
        attributes: { target: "_blank", rel: "noopener" },
      },
    ],
  },*/
  {
    title: true,
    name: "Administration",
    permission: "log,users,configstreams",
  },
  {
    name: "Stream Editor",
    url: "/stream_configuration",
    icon: "fa fa-wrench",
    permission: "configstreams",
    badge: {
      variant: "danger",
      text: "Beta",
    },
  },
  {
    name: "Logs",
    url: "/logs",
    icon: "fa fa-pencil-square-o",
    permission: "log",
  },
  {
    name: "Users",
    url: "/users/",
    icon: "fa fa-users",
    permission: "users",
  },
];
