import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { LogViewerComponent } from "./log-viewer/log-viewer.component";
import { EditUsersComponent } from "./edit-users/edit-users.component";
import { StreamConfigurationComponent } from "./stream-configuration/stream-configuration.component";
import { MapPageComponent } from "./map-page/map-page.component";
export const routes: Routes = [
  //  {
  //    path: '',
  //    redirectTo: 'video',
  //    pathMatch: 'full',
  //  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   data: {
  //     title: 'Page 404'
  //   }
  // },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      /*{
        path: "",
        component: DefaultLayoutComponent,
        redirectTo: "map",
        pathMatch: "full",
      },*/
      {
        path: "video",
        loadChildren: () =>
          import("./video/video.module").then((m) => m.VideoModule),
      },
      {
        path: "map",
        component: MapPageComponent,
        data: {
          title: "Map",
        },
      },
      {
        path: "logs",
        component: LogViewerComponent,
        data: {
          title: "Logs",
        },
      },
      {
        path: "users",
        component: EditUsersComponent,
        data: {
          title: "Users",
        },
      },
      {
        path: "stream_configuration",
        component: StreamConfigurationComponent,
        data: {
          title: "Stream Configuration",
        },
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
